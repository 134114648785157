body {
   font-family: "Big Shoulders Display", sans-serif;
   color: rgb(151, 171, 230);
}
#root {
   height: 100vh;
   width: 100vw;
}
.cookie-banner {
   position: fixed;
   bottom: 0px;
   width: 100%;
   color: white;
   padding: 5px 14px;
   font-size: 13px;
   align-items: center;
   justify-content: space-between;
   background: rgb(50, 50, 50, 0.9);
   box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
   z-index: 1205;
}

.main {
   width: 100%;
   height: 800px;
   border-bottom: 3px solid #ddd;
   box-shadow: -1px -11px 20px 0px rgba(0, 0, 0, 0.75) inset;
   -webkit-box-shadow: -1px -11px 20px 0px rgba(0, 0, 0, 0.75) inset;
   -moz-box-shadow: -1px -11px 20px 0px rgba(0, 0, 0, 0.75) inset;
}

.video {
   width: 100%;
   height: 100%;
   object-fit: cover;
}
.image {
   width: 100%;
   height: 100%;
   object-fit: cover;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
}
.title-wrapper {
   font-size: 3vh;
   color: #fff;
   color: rgb(255, 255, 255);
   text-shadow: 2px 2px 2px rgb(2, 1, 1);
   max-width: 820px;
   padding: 20px;
   margin-left: auto;
   margin-right: auto;
}

.body-wrapper {
   font-size: 20px;
   color: #ddd;
   text-shadow: 1px 1px 2px rgb(2, 1, 1);
   display: block;
   max-width: 840px;
   padding: 35px;
   margin-left: auto;
   margin-right: auto;
}
.body-child {
   min-height: 600px;
}
.foot-wrapper {
   margin-left: auto;
   margin-right: auto;
}
.overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   z-index: 5;
   display: block;
   /* background-color: #000000cc; */
}
.vertical-center {
   margin-top: 200;
   position: absolute;
   top: 290px;
   -ms-transform: translateY(-50%);
   transform: translateY(-50%);
   padding: 10px;
}
.center-inner {
   display: table;
   margin: 0 auto;
}

.center-outer {
   width: 100%;
}
.center {
   margin: auto;
   text-align: center;
}

.swing_div {
   max-width: 390px;
   height: 40px;
}
.fixed-bottom-right {
   position: fixed;
   bottom: 15px;
   right: 15px;
   color: #fff; /* Text color, customize as needed */
   z-index: 1000;
}
